<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Upcoming Activity</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <upcoming-activity-table
          :data="activity"
          :paginate="paginate"
          :onSetActive="onSetActive"
          :onUpdatePaginate="onUpdatePaginate"
          :onUpdateLimit="onUpdateLimit"
          :onUpdateSort="onUpdateSort"
          :onDeleteData="onDeleteData"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import UpcomingActivityTable from '@/components/tables/UpcomingActivityTable'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  components: {
    'upcoming-activity-table': UpcomingActivityTable
  },
  data: () => ({
    enable: true,
    activity: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.GUFAMILY.ACTIVITY.LIST,
      createData: ActionFetch.GUFAMILY.ACTIVITY.CREATE,
      updateData: ActionFetch.GUFAMILY.ACTIVITY.UPDATE,
      updateSort: ActionFetch.GUFAMILY.ACTIVITY.UPDATESORT,
      deleteData: ActionFetch.GUFAMILY.ACTIVITY.DELETE
    }),
    onDeleteData ($data) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($conRes) => {
          if ($conRes) {
            this.deleteData($data.id).then(($res) => {
              if ($res.success) {
                this.fetchList(this.paginate.page)
              }
            })
          }
        })
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchList($paginate)
    },
    onUpdateSort ($data) {
      this.updateSort($data).then(($res) => {
        if ($res.success) {
          this.fetchList(this.paginate.page)
        }
      })
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.pagiante.page = 1

      this.fetchList()
    },
    onSetActive ($data, $source) {
      $source.status = $data.value ? 'Active' : 'Inactive'

      let dataSave = {
        id: $source.id,
        status: $source.status
      }

      this.updateData(dataSave)
    },
    fetchList ($page = 1) {
      const payload = {
        orderBy: 'orderNum',
        direction: 'asc',
        limit: this.paginate.limit,
        page: $page
      }

      this.getList(payload).then(($res) => {
        if ($res.success) {
          this.activity = $res.data.map(($data) => {
            if ($data.endDate) {
              const endDate = new Date($data.endDate)
              let dd = endDate.getDate()
              let mm = endDate.getMonth() + 1
              let yy = endDate.getFullYear()
              const currentEndDate = new Date(`${yy}-${mm}-${dd} 23:59:59`)
              const currentDate = new Date()

              if (currentEndDate < currentDate) {
                $data.status = 'Inactive'
              }
            }

            return $data
          })
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    }
  },
  created () {
    this.fetchList()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>
