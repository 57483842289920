<template>
  <div>
    <div class="table-responsive">
      <div class="flex-row align-center justify-between">
        <h5 class="page-title" style="font-weight: 600">Setting Milestone</h5>
        <div class="flex-row">
          <b-btn
            size="sm"
            variant="primary"
            class="btn-group-space"
            @click="view(0)"
            v-b-modal.edit-news
            >Add New Item</b-btn
          >
        </div>
      </div>

      <br />
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th width="300" class="text-center">Year</th>
            <th width="300" class="text-center">Last Update</th>
            <th width="300" class="text-center">Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="list in data" :key="list.id">
            <td>
              <b-form-select
                :value="list.orderNum"
                :sync="true"
                v-on:change="updateSorting($event, list)"
              >
                <option
                  v-for="(ls, index) in orderList"
                  :value="ls"
                  :key="index + 1"
                >
                  {{ index + 1 }}
                </option>
              </b-form-select>
            </td>
            <td class="text-center">{{ list.year.th }} / {{ list.year.en }}</td>
            <td class="text-center">{{ renderDate(list.updatedAt) }}</td>
            <td class="text-center">
              <toggle-button
                :value="list.status.toLowerCase() === 'active'"
                :labels="true"
                @change="(e) => onSetActive(e, list)"
              />
            </td>

            <td class="text-center">
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  @click="view(list.id)"
                  class="mx-1"
                  >Edit</b-btn
                >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  @click="(e) => onDeleteMilestone(list.id)"
                  >Delete</b-button
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        v-on:change="onUpdatePaginate"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    onSetActive: Function,
    onUpdatePaginate: Function,
    onUpdateLimit: Function,
    onUpdateSort: Function,
    onDeleteMilestone: Function,
    paginate: {
      type: Object,
      default: () => {
        return {
          limit: 10,
          page: 1,
          totalPage: 1,
          totalRow: 0
        }
      }
    }
  },
  components: {},
  data: () => ({
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    orderList: []
  }),
  methods: {
    view (id) {
      this.$router.push(`/about/milestone/${id}/edit`)
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    renderDataLang ($data) {
      return $data.th ? $data.th : $data.en
    },
    updateSorting ($newVal, $data) {
      let dataSort = {
        id: $data.id,
        orderNew: $newVal,
        orderOld: $data.orderNum
      }

      this.onUpdateSort(dataSort)
    }
  },
  watch: {
    'paginate.totalRow': function (newVal, oldVal) {
      this.orderList = []
      for (let ls = 1; ls <= newVal; ls++) {
        this.orderList.push(ls)
      }
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}
.title-table {
  width: 30%;
}

.text-center {
  text-align: center;
}
/* th {
  text-align: center;
}
td {
  text-align: center;
} */
</style>
