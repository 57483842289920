<template>
  <div>
    <div class="page-name">
      <div class="page-title">User & Role Manager</div>
    </div>

    <div class="page-container">
      <form id="roleForm" @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting User Account</h5>
            </div>
          </div>
          <hr />

          <b-form-group style="font-weight: 600" label="Role">
            <b-form-select class="flex-fill" v-model="form.role" required>
              <option
                v-for="(ls, index) of roleList"
                :value="ls.id"
                :key="index + 1"
              >
                {{ ls.name }}
              </option>
            </b-form-select>
          </b-form-group>

          <b-form-group style="font-weight: 600" label="Name">
            <b-form-input
              type="text"
              placeholder="Input Name"
              v-model="form.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="blue-label"
            style="font-weight: 600"
            label="Email Address (* This is data for login to CMS)"
          >
            <b-form-input
              type="text"
              placeholder="Inpue Email"
              v-model="form.email"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="blue-label"
            style="font-weight: 600"
            label="Password"
          >
            <b-form-input
              type="password"
              placeholder="Input Password"
              v-model="form.password"
            ></b-form-input>
          </b-form-group>

          <div class="footer-btn-zone">
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              size="sm"
              variant="secondary"
              class="mx-1"
              type="button"
              @click="this.goBack"
              >Cancel</b-btn
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    form: {
      role: '',
      name: '',
      email: '',
      password: ''
    },
    roleList: []
  }),
  methods: {
    ...mapActions({
      getRoleList: ActionFetch.ROLE.LIST,
      createData: ActionFetch.USER.CREATE,
      getInfo: ActionFetch.USER.DETAIL,
      updateData: ActionFetch.USER.UPDATE
    }),
    goBack () {
      this.$router.push(`/role/user`)
    },
    onSubmit ($e) {
      $e.preventDefault()

      const validateResult = this.validateEmail(this.form.email)

      if (!this.form.role) {
        this.$bvToast.toast(`Please select role`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.name) {
        this.$bvToast.toast(`Please input name`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (validateResult) {
        if (!this.form.id) {
          const validatePassword = this.validatePassword(this.form.password)

          if (validatePassword) {
            this.createData(this.form)
              .then(($res) => {
                if ($res.success) {
                  this.$bvToast.toast(`Create user successful`, {
                    title: 'User created',
                    autoHideDelay: 2000,
                    appendToast: true,
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right',
                    solid: true
                  })

                  setTimeout(() => {
                    this.$router.push(`/role/user`)
                  }, 1000)
                } else {
                  this.$bvToast.toast($res.message, {
                    title: 'Error',
                    autoHideDelay: 2000,
                    appendToast: true,
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right',
                    solid: true
                  })
                }
              })
              .catch(($ex) => {
                this.$bvToast.toast($ex.message, {
                  title: 'Error',
                  autoHideDelay: 2000,
                  appendToast: true,
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right',
                  solid: true
                })
              })
          }
        } else {
          const payload = this.form
          let validatePassword = true

          if (!this.form.password) {
            delete payload.password
          } else {
            validatePassword = this.validatePassword(this.form.password)
          }

          delete payload.lastLogin

          if (validatePassword) {
            this.updateData(payload)
              .then(($res) => {
                if ($res.success) {
                  this.$bvToast.toast(`Update user successful`, {
                    title: 'User updated',
                    autoHideDelay: 2000,
                    appendToast: true,
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right',
                    solid: true
                  })

                  setTimeout(() => {
                    this.$router.push(`/role/user`)
                  }, 1000)
                } else {
                  this.$bvToast.toast($res.message, {
                    title: 'Error',
                    autoHideDelay: 2000,
                    appendToast: true,
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right',
                    solid: true
                  })
                }
              })
              .catch(($ex) => {
                this.$bvToast.toast($ex.message, {
                  title: 'Error',
                  autoHideDelay: 2000,
                  appendToast: true,
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right',
                  solid: true
                })
              })
          }
        }
      }
    },
    validatePassword ($password = '') {
      let result = false
      const regPassword = /^[a-zA-Z0-9_]+$/

      if ($password.length < 6) {
        this.$bvToast.toast(`Please input password at least 6 charactor`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if ($password.length > 8) {
        this.$bvToast.toast(`Password maximum 8 charactor`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (regPassword.test($password)) {
        result = true
      } else {
        this.$bvToast.toast(`Password invalid`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }

      return result
    },
    validateEmail ($email = '') {
      let result = false
      const regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

      if (!$email) {
        this.$bvToast.toast(`Please input email`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (regEmail.test($email)) {
        result = true
      } else {
        this.$bvToast.toast(`Email invalid`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }

      return result
    },
    fetchInfo () {
      const params = {
        id: +this.$route.params.id
      }

      if (+this.$route.params.id) {
        this.getInfo(params).then(($res) => {
          if ($res.success) {
            this.form = { ...$res.data }

            this.form.password = ''

            delete this.form.createdAt
            delete this.form.updatedAt
          }
        })
      }
    },
    fetchRoleList () {
      this.getRoleList().then(($res) => {
        if ($res.success) {
          this.roleList = $res.data
        }
      })
    }
  },
  created () {
    this.fetchRoleList()
    this.fetchInfo()
  }
}
</script>
<style>
.mt-2vh {
  margin-top: 4vh;
}

.row-space-top {
  margin-top: 3vh;
}

.footer-btn-zone {
  margin-top: 5vh;
}
</style>
