<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">About Us</div>
    </div>
    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting Page Banner</h5>
            </div>
          </div>
          <hr />

          <div id="thumbnail-section">
            <div id="thumbs-1" class="thumb-item">
              <div class="flex-row">
                <div class="thumbnail-picker-wrapper">
                  <ImageUploadNew
                    :dataImage="{ ...form.image }"
                    :showError="uploadError"
                    :isRequired="false"
                    :maxFile="1"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="onImageUpload"
                  />
                </div>
                <div class="flex-1 pl-10-px">
                  <b-form-group
                    style="font-weight: bold; color: #666"
                    label="Thumbnail (1920 x 550 pixel)"
                  >
                    <div class="flex-row col-10 no-padding">
                      <p
                        style="
                          margin-right: 5px;
                          flex: 1 15%;
                          line-height: 30px;
                        "
                      >
                        ALT Text
                      </p>
                      <b-form-input
                        class=""
                        v-model="form.image.name"
                        type="text"
                        placeholder="Typing Something..."
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  required
                  v-model="form.title.th"
                  type="text"
                  placeholder="Input Title"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle">
                <b-form-input
                  v-model="form.subtitle.th"
                  type="text"
                  placeholder="Input Subtitle"
                ></b-form-input>
              </b-form-group>
            </b-tab>

            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  required
                  v-model="form.title.en"
                  type="text"
                  placeholder="Input Title"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle">
                <b-form-input
                  v-model="form.subtitle.en"
                  type="text"
                  placeholder="Input Subtitle"
                ></b-form-input>
              </b-form-group>
            </b-tab>
          </b-tabs>

          <hr />

          <div>
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              size="sm"
              variant="secondary"
              class="mx-1"
              type="button"
              @click="fetchBanner"
              >Cancel</b-btn
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import ImageUploadNew from '@/components/ImageUploadNew'

export default {
  data: () => ({
    tab: 'th',
    form: {
      title: {
        th: '',
        en: ''
      },
      image: {
        source: '',
        name: ''
      },
      subtitle: {
        th: '',
        en: ''
      }
    },
    uploadSetting: {
      showPreview: true,
      showDelete: true,
      showAlt: false
    },
    articleThumb: {
      source: '',
      name: ''
    },
    uploadError: false
  }),
  components: {
    ImageUploadNew
  },
  methods: {
    ...mapActions({
      getInfo: ActionFetch.ABOUT.BANNER.DETAIL,
      updateBanner: ActionFetch.ABOUT.BANNER.UPDATE
    }),
    fetchBanner () {
      this.getInfo().then(($res) => {
        if ($res.success) {
          this.form = $res.data

          if (!$res.data.image.source) {
            this.form.image = {
              source: '',
              name: ''
            }
          }
        }
      })
    },
    onImageUpload ($data) {
      this.form.image.source = $data.source
    },
    onSubmit ($e) {
      $e.preventDefault()
      if (!this.form.image.source) {
        this.uploadError = true
      } else {
        this.uploadError = false

        if (this.form.title.th && this.form.title.en) {
          this.updateBanner(this.form).then(($res) => {
            if ($res.success) {
              this.form = $res.data

              this.$bvToast.toast(`Update Setting banner successful`, {
                title: 'Setting banner updated',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            }
          })
        } else {
          this.$bvToast.toast(`Please input title`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        }
      }
    }
  },
  created () {
    this.fetchBanner()
  }
}
</script>
<style>
.thumb-item {
  margin-bottom: 10px;
}
.pl-10-px {
  padding-left: 10px;
}
label {
  font-weight: bold;
}
.form-group {
  padding: 0;
}
.no-padding {
  padding: 0;
}
.inline-zone {
  min-width: 25%;
}
.maxWidth {
  max-width: 250px;
  min-width: 120px;
}
.hidden {
  opacity: 0;
}
</style>
