<template>
  <div id="">
    <div class="page-name">
      <div class="page-title">Upcoming Activity</div>
    </div>

    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Add/Edit Activity List</h5>
            </div>
          </div>

          <div id="thumbnail-section">
            <div id="thumbs-1" class="thumb-item">
              <div class="flex-row">
                <div class="thumbnail-picker-wrapper">
                  <ImageUploadNew
                    :dataImage="{ ...form.thumb[0] }"
                    :showError="uploadThumbnailError"
                    :isRequired="false"
                    :maxFile="1"
                    :onFileUploaded="onThumbnailUploaded"
                    :uploadSetting="{ ...uploadSetting }"
                  />
                </div>
                <div class="flex-1">
                  <b-form-group
                    style="font-weight: 600"
                    label="Thumbnail (500 x 280 pixel)"
                  >
                    <div class="flex-row">
                      <p
                        style="margin-right: 5px; flex: 1 10%"
                        class="alt-thumb"
                      >
                        ALT Text
                      </p>
                      <b-form-input
                        type="text"
                        v-model="form.thumb[0].name"
                        placeholder="Typing Something..."
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <b-row>
            <b-col lg="4">
              <div class="form-group form-group-row">
                <span class="inline-zone">Post Date</span>
                <date-picker
                  lang="en"
                  v-model="form.showDate"
                  required
                  placeholder="Select Date"
                  class="flex-fill"
                />
              </div>
            </b-col>
          </b-row>

          <hr />

          <div class="row" style="text-align: left">
            <div class="col-md-6">
              <div class="form-group form-group-row">
                <span class="inline-zone">Published Date:</span>
                <span style="font-weight: 600; margin-right: 10px"
                  >Date Start</span
                >
                <date-picker
                  lang="en"
                  v-model="form.startDate"
                  placeholder="Select Date"
                  class="date-picker"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group form-group-row">
                <span style="font-weight: 600; margin-right: 10px"
                  >Date Stop</span
                >
                <date-picker
                  lang="en"
                  v-model="form.endDate"
                  placeholder="Select Date"
                  class="date-picker"
                  :not-before="renderDate(form.startDate)"
                />
              </div>
            </div>
          </div>

          <hr />

          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <b-form-group>
                <label for class="label">
                  <span style="font-weight: 600; margin-right: 10px"
                    >Page URL</span
                  >
                  <span class="red"
                    >(use only non-capital letters, no space)</span
                  >
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ getDefaultUrl("th") }}
                    </div>
                  </div>
                  <b-form-input
                    id="urlTH"
                    class="col-lg-4"
                    type="text"
                    placeholder="detail"
                    v-model="form.url.th"
                    v-bind:class="{ 'url-error': urlVerify.th }"
                    @keyup="(e) => onUpdatePageUrl(e, 'th')"
                  ></b-form-input>

                  <a
                    @click="copy(form.url.th, 'urlThCpyZone', 'th')"
                    class="btn-copy"
                    v-b-tooltip.hover.bottom
                    title="Copy URL"
                  >
                    <i class="fas fa-clipboard"></i>
                  </a>
                  <div id="urlThCpyZone" class="hidden"></div>
                </div>
              </b-form-group>

              <hr />

              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  type="text"
                  placeholder="Title"
                  v-model="form.title.th"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Detail">
                <TextEditor
                  :content="form.content.th"
                  :onUpdateInfo="(e) => onUpdateContent(e, 'th')"
                />
              </b-form-group>
            </b-tab>
            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <b-form-group>
                <label for class="label">
                  <span style="font-weight: 600; margin-right: 10px"
                    >Page URL</span
                  >
                  <span class="red"
                    >(use only non-capital letters, no space)</span
                  >
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ getDefaultUrl("en") }}
                    </div>
                  </div>
                  <b-form-input
                    id="urlEN"
                    class="col-lg-4"
                    type="text"
                    placeholder="detail"
                    v-model="form.url.en"
                    v-bind:class="{ 'url-error': urlVerify.en }"
                    @keyup="(e) => onUpdatePageUrl(e, 'en')"
                  ></b-form-input>

                  <a
                    @click="copy(form.url.en, 'urlEnCpyZone', 'en')"
                    class="btn-copy"
                    v-b-tooltip.hover.bottom
                    title="Copy URL"
                  >
                    <i class="fas fa-clipboard"></i>
                  </a>
                  <div id="urlEnCpyZone" class="hidden"></div>
                </div>
              </b-form-group>

              <hr />

              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  type="text"
                  placeholder="Title"
                  v-model="form.title.en"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Detail">
                <TextEditor
                  :content="form.content.en"
                  :onUpdateInfo="(e) => onUpdateContent(e, 'en')"
                />
              </b-form-group>
            </b-tab>
          </b-tabs>

          <hr />

          <div class="table-responsive">
            <table class="table">
              <thead>
                <th class="orderBox text-left">Sort</th>
                <th class="gallery-img-container text-left">Images</th>
              </thead>
              <tbody>
                <tr v-for="(img, index) in this.form.gallery" :key="index">
                  <td>
                    <b-form-select
                      class="orderBox"
                      name
                      id
                      v-model="img.orderNum"
                      @change="(value) => onSwapImg(value, index)"
                    >
                      <option
                        v-for="i in form.gallery.length"
                        :value="i - 1"
                        :key="i"
                      >
                        {{ i }}
                      </option>
                    </b-form-select>
                  </td>
                  <td>
                    <div class="gallery-img-container">
                      <ImageUploadNew
                        :dataImage="{ ...img }"
                        :uploadSetting="{ ...uploadSettingGellery }"
                        :onFileUploaded="(e) => onUploadGallery(e, img, index)"
                      />
                    </div>
                  </td>
                </tr>
                <tr v-if="form.gallery.length === 0">
                  <td colspan="5">
                    <div class="text-center">
                      <i>Empty data.</i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <FileUploadMulti
            :multiple="true"
            :files="newGallery"
            :title="'Upload Files'"
            :className="'wrapper-files file-upload-gallery'"
            :allowImagePreview="false"
            :instantUpload="true"
            :onFileUploaded="onUploaded"
          />

          <hr />
          <div class="form-group form-group-row">
            <b-form-group
              class="mb-0 col-12"
              label="Video (Youtube)"
              label-for="input-formatter"
              style="font-weight: 600"
            >
            </b-form-group>
          </div>

          <table class="table custom-news">
            <tbody>
              <tr v-for="(item, index) in form.youtube" :key="index">
                <td width="120">
                  <span v-if="onCheckEditMode(index)">Youtube Link</span>
                  <b-form-select
                    class="w-70px"
                    name
                    id
                    v-model="item.orderNum"
                    v-if="!onCheckEditMode(index)"
                    @change="(e) => onSwapIndex(e, index)"
                  >
                    <option
                      v-for="(orderList, orderIndex) in form.youtube.length"
                      :key="orderIndex"
                      :value="orderIndex"
                    >
                      {{ orderList }}
                    </option>
                  </b-form-select>
                </td>
                <td>
                  <span v-if="!onCheckEditMode(index)">{{ item.url }}</span>
                  <input
                    :value="item.url"
                    class="form-control"
                    placeholder="Youtube URL"
                    required
                    type="text"
                    v-on:keyup="(e) => onEditYoutubeUrl(e, index)"
                    v-if="onCheckEditMode(index)"
                  />
                </td>
                <td>
                  <b-button-group>
                    <b-btn
                      size="sm"
                      variant="primary"
                      class="mx-1"
                      v-if="onCheckEditMode(index)"
                      @click="saveEditMode(index)"
                    >
                      Save
                    </b-btn>
                    <b-btn
                      size="sm"
                      variant="outline-primary"
                      class="mx-1"
                      v-if="!onCheckEditMode(index)"
                      @click="openEditMode(index, item.url)"
                    >
                      Edit
                    </b-btn>
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      class="btn-group-space"
                      v-if="!onCheckEditMode(index)"
                      @click="onDeleteYoutube(index)"
                      >Delete</b-button
                    >
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      class="btn-group-space"
                      v-if="onCheckEditMode(index)"
                      @click="saveEditMode(index, false)"
                      >Cancel</b-button
                    >
                  </b-button-group>
                </td>
              </tr>
              <tr class="footer">
                <td colspan="2">
                  <b-btn
                    size="sm"
                    variant="primary"
                    class="mx-1 add-u-btn"
                    @click="onAddYoutube"
                    >ADD</b-btn
                  >
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <hr />

          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <div class="flex-col" style="flex: 1">
                <h6 class="page-title-bg">SEO</h6>
              </div>
              <b-form-group style="font-weight: 600" label="Meta tag keywords">
                <b-form-input
                  v-model="form.seoMetaKeyword.th"
                  type="text"
                  placeholder="Input meta tag keywords"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                style="font-weight: 600"
                label="Meta tag description"
              >
                <b-form-input
                  v-model="form.seoMetaDescription.th"
                  type="text"
                  placeholder="Input meta tag description"
                ></b-form-input>
              </b-form-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Header">
                    <b-form-textarea
                      v-model="form.seoTagInHeader.th"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Body">
                    <b-form-textarea
                      v-model="form.seoTagInBody.th"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
              </div>
              <div class="flex-col" style="flex: 1">
                <h6 class="page-title-bg">Facebook</h6>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Header">
                    <b-form-textarea
                      v-model="form.facebookTagInHeader.th"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Body">
                    <b-form-textarea
                      v-model="form.facebookTagInBody.th"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-tab>
            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <div class="flex-col" style="flex: 1">
                <h6 class="page-title-bg">SEO</h6>
              </div>
              <b-form-group style="font-weight: 600" label="Meta tag keywords">
                <b-form-input
                  v-model="form.seoMetaKeyword.en"
                  type="text"
                  placeholder="Input meta tag keywords"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                style="font-weight: 600"
                label="Meta tag description"
              >
                <b-form-input
                  v-model="form.seoMetaDescription.en"
                  type="text"
                  placeholder="Input meta tag description"
                ></b-form-input>
              </b-form-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Header">
                    <b-form-textarea
                      v-model="form.seoTagInHeader.en"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Body">
                    <b-form-textarea
                      v-model="form.seoTagInBody.en"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
              </div>
              <div class="flex-col" style="flex: 1">
                <h6 class="page-title-bg">Facebook</h6>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Header">
                    <b-form-textarea
                      v-model="form.facebookTagInHeader.en"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group style="font-weight: 600" label="Tag in Body">
                    <b-form-textarea
                      v-model="form.facebookTagInBody.en"
                      placeholder="HTML/Javascript code"
                      rows="3"
                      max-rows="6"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <div class="form-group form-group-row">
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-button
              size="sm"
              variant="outline-danger"
              class="btn-group-space"
              @click="back()"
              >Cancel</b-button
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import ImageUploadNew from '@/components/ImageUploadNew'
import FileUploadMulti from '@/components/FileUploadMulti'
import TextEditor from '@/components/text-editor/TextEditor'

export default {
  data: () => ({
    tab: 'th',
    urlVerify: {
      th: '',
      en: ''
    },
    form: {
      thumb: [
        {
          source: '',
          name: ''
        }
      ],
      title: {
        th: '',
        en: ''
      },
      url: {
        th: '',
        en: ''
      },
      fullUrl: {
        th: '',
        en: ''
      },
      content: {
        th: '',
        en: ''
      },
      gallery: [],
      youtube: [],
      showDate: null,
      startDate: null,
      endDate: null,
      status: 'Inactive',
      seoMetaKeyword: {
        th: '',
        en: ''
      },
      seoTagInHeader: {
        th: '',
        en: ''
      },
      seoTagInBody: {
        th: '',
        en: ''
      },
      facebookTagInHeader: {
        th: '',
        en: ''
      },
      facebookTagInBody: {
        th: '',
        en: ''
      },
      seoMetaDescription: {
        th: '',
        en: ''
      }
    },
    uploadThumbnailError: false,
    uploadSetting: {
      showPreview: true,
      showDelete: true,
      showAlt: false
    },
    uploadSettingGellery: {
      showPreview: true,
      showDelete: true,
      showAlt: true
    },
    newGallery: [],
    editModeList: []
  }),
  components: {
    ImageUploadNew,
    FileUploadMulti,
    TextEditor
  },
  methods: {
    ...mapActions({
      createData: ActionFetch.GUFAMILY.ACTIVITY.CREATE,
      getInfo: ActionFetch.GUFAMILY.ACTIVITY.DETAIL,
      updateData: ActionFetch.GUFAMILY.ACTIVITY.UPDATE
    }),
    back () {
      this.$router.push(`/gu-family/activity/`)
    },
    copy (url, target, $lang) {
      const inputDestination = document.createElement('input')

      inputDestination.value = `${this.getDefaultUrl($lang)}/${url}/${
        this.form.id
      }`

      document.getElementById(target).appendChild(inputDestination)
      inputDestination.focus()
      inputDestination.select()
      document.execCommand('copy')
      setTimeout(() => {
        document.getElementById(target).innerHTML = ''
      }, 1000)
    },
    getDefaultUrl ($lang) {
      return `${process.env.VUE_APP_DOMAIN}/${$lang}/GrandU-family/upcoming-activity`
    },
    renderDate ($date) {
      return format($date, 'YYYY-MM-DD')
    },
    onUpdatePageUrl ($data, $lang) {
      let regCharactor = /^[a-zก-๙0-9-]+$/

      if ($data.target.value) {
        this.form.url[$lang] = $data.target.value
          .replace(/ /g, '-')
          .toLowerCase()
        this.urlVerify[$lang] = !regCharactor.test(this.form.url[$lang])
      }
    },
    onUploaded ($data) {
      let newUploadList = $data
        .filter(($upload) => {
          const imgGallery = this.form.gallery.find(($gallery) => {
            return $gallery.source === $upload.source
          })
          return !imgGallery
        })
        .map(($upload, $index) => {
          return { ...$upload, orderNum: $index + this.form.gallery.length }
        })

      setTimeout(() => {
        this.newGallery = []
      }, 1000)

      this.form.gallery = this.form.gallery.concat(newUploadList)
    },
    onUploadGallery ($event, $img, $index) {
      if ($event.source === '') {
        this.form.gallery.splice($index, 1)
      } else {
        $img.source = $event.source
        $img.name = $event.name
      }
    },
    onUpdateContent ($value, $lang) {
      this.form.content[$lang] = $value
    },
    onEditYoutubeUrl ($event, $index) {
      let indexEditMode = this.editModeList.findIndex(($data) => {
        return $data.listIndex === $index
      })

      if (indexEditMode >= 0) {
        this.editModeList[indexEditMode].data = $event.target.value
      }
    },
    openEditMode ($index, $data) {
      this.editModeList.push({
        listIndex: $index,
        data: $data
      })
    },
    onSwapIndex ($nextIndex, $index) {
      let tempUrlIndex = this.form.youtube[$index].url

      this.form.youtube[$index].url = this.form.youtube[$nextIndex].url
      this.form.youtube[$nextIndex].url = tempUrlIndex
      this.form.youtube[$index].orderNum = $index
    },
    onSwapImg ($nextIndex, $index) {
      let tempDataIndex = {
        source: this.form.gallery[$index].source,
        name: this.form.gallery[$index].name
      }

      this.form.gallery[$index].source = this.form.gallery[$nextIndex].source
      this.form.gallery[$index].name = this.form.gallery[$nextIndex].name
      this.form.gallery[$nextIndex].source = tempDataIndex.source
      this.form.gallery[$nextIndex].name = tempDataIndex.name
      this.form.gallery[$index].orderNum = $index
    },
    saveEditMode ($index, $isSave = true) {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
      let indexEditMode = this.editModeList.findIndex(($data) => {
        return $data.listIndex === $index
      })

      if (indexEditMode >= 0) {
        var match = this.editModeList[indexEditMode].data.match(regExp)

        if ((match && match[2].length === 11) || !$isSave) {
          if ($isSave) {
            this.form.youtube[$index].url = this.editModeList[indexEditMode].data
          }

          this.editModeList.splice(indexEditMode, 1)

          if (!this.form.youtube[$index].url) {
            this.form.youtube.splice($index, 1)
          }
        } else {
          this.$bvToast.toast(`Invalid`, {
            title: 'Youtube link invalid format',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        }
      }
    },
    onUpdateYoutube ($event, $index) {
      this.form.youtube[$index].url = $event.target.value
    },
    onAddYoutube () {
      this.editModeList.push({
        listIndex: this.form.youtube.length,
        data: ''
      })

      this.form.youtube.push({
        name: '',
        url: '',
        status: 'Active',
        orderNum: this.form.youtube.length
      })
    },
    onDeleteYoutube ($index) {
      this.form.youtube.splice($index, 1)
      this.form.youtube = this.form.youtube.map(($youtube, $index) => {
        $youtube.orderNum = $index
        return $youtube
      })
    },
    onCheckEditMode ($index) {
      let editModeIndex = this.editModeList.find(($data) => {
        return $data.listIndex === $index
      })

      return !!editModeIndex
    },
    onThumbnailUploaded ($data) {
      this.form.thumb[0].source = $data.source
    },
    fetchInfo () {
      const params = {
        id: +this.$route.params.id
      }

      if (+this.$route.params.id) {
        this.getInfo(params).then(($res) => {
          if ($res.success) {
            this.form = { ...$res.data }

            if (!$res.data.fullUrl) {
              this.form.fullUrl = {
                th: '',
                en: ''
              }
            }
          }
        })
      }
    },
    onSubmit ($e) {
      $e.preventDefault()

      if (!this.form.thumb[0].source) {
        this.uploadThumbnailError = true
      }

      const formSave = { ...this.form }

      formSave.showDate = this.form.showDate
        ? format(this.form.showDate, 'YYYY-MM-DD')
        : null
      formSave.startDate = this.form.startDate
        ? format(this.form.startDate, 'YYYY-MM-DD')
        : null
      formSave.endDate = this.form.endDate
        ? format(this.form.endDate, 'YYYY-MM-DD')
        : null

      let canSave = false

      if (!this.form.startDate && this.form.endDate) {
        this.$bvToast.toast(`Please input start date`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.endDate && this.form.startDate) {
        this.$bvToast.toast(`Please input end date`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!formSave.showDate) {
        this.$bvToast.toast(`Please input post date`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.content.en) {
        this.$bvToast.toast(`Please input english content`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.title.en) {
        this.$bvToast.toast(`Please input english title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.url.en) {
        this.$bvToast.toast(`Please input english url`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.title.th) {
        this.$bvToast.toast(`Please input thai title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.url.th) {
        this.$bvToast.toast(`Please input thai url`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.content.th) {
        this.$bvToast.toast(`Please input thai content`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (this.urlVerify.th) {
        this.$bvToast.toast(`Invalid thai url`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (this.urlVerify.en) {
        this.$bvToast.toast(`Invalid url english`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        canSave = true
      }

      // NOTE: for used when api fix bug fullUrl
      // if (!formSave.showDate) {
      //   this.$bvToast.toast(`Please input post date`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else if (!this.form.content.en && !this.form.content.th) {
      //   this.$bvToast.toast(`Please input content data`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else if (this.form.content.en && !this.form.title.en) {
      //   this.$bvToast.toast(`Please input title english`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else if (this.form.content.en && !this.form.url.en) {
      //   this.$bvToast.toast(`Please input url english`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else if (this.form.content.th && !this.form.title.th) {
      //   this.$bvToast.toast(`Please input title thai`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else if (this.form.content.th && !this.form.url.th) {
      //   this.$bvToast.toast(`Please input url thai`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else if (this.urlVerify.th) {
      //   this.$bvToast.toast(`Invalid url thai`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else if (this.urlVerify.en) {
      //   this.$bvToast.toast(`Invalid url english`, {
      //     title: "Form Invalid",
      //     autoHideDelay: 2000,
      //     appendToast: true,
      //     variant: "warning",
      //     toaster: "b-toaster-bottom-right",
      //     solid: true
      //   })
      // } else {
      //   canSave = true
      // }

      if (canSave) {
        if (!this.form.id) {
          this.createData(formSave).then(($res) => {
            if ($res.success) {
              this.form.id = $res.data.id
              formSave.id = $res.data.id

              if (this.form.content.th) {
                formSave.fullUrl.th = `${this.getDefaultUrl('th')}/${
                  formSave.url.th
                }/${$res.data.id}`
              } else {
                formSave.fullUrl.th = null
              }

              if (this.form.content.en) {
                formSave.fullUrl.en = `${this.getDefaultUrl('en')}/${
                  formSave.url.en
                }/${$res.data.id}`
              } else {
                formSave.fullUrl.en = null
              }

              this.updateData(formSave).then(($resUpdate) => {
                if ($res.success) {
                  this.$bvToast.toast(`Create upcoming activity successful`, {
                    title: 'Upcoming activity created',
                    autoHideDelay: 2000,
                    appendToast: true,
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right',
                    solid: true
                  })

                  setTimeout(() => {
                    this.$router.push(`/gu-family/activity/`)
                  }, 1000)
                }
              })
            }
          })
        } else {
          delete formSave.prev
          delete formSave.next

          formSave.fullUrl = {
            th: '',
            en: ''
          }

          if (this.form.content.th) {
            formSave.fullUrl.th = `${this.getDefaultUrl('th')}/${
              formSave.url.th
            }/${formSave.id}`
          }

          if (this.form.content.en) {
            formSave.fullUrl.en = `${this.getDefaultUrl('en')}/${
              formSave.url.en
            }/${formSave.id}`
          }

          this.updateData(formSave).then(($res) => {
            if ($res.success) {
              this.$bvToast.toast(`Update upcoming activity successful`, {
                title: 'Upcoming activity updated',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })

              setTimeout(() => {
                this.$router.push(`/gu-family/activity/`)
              }, 1000)
            }
          })
        }
      }
    }
  },
  created () {
    this.fetchInfo()
  }
}
</script>

<style>
.inline-zone {
  margin-right: 10px;
  font-weight: 600;
  min-width: 25%;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
  min-width: 120px;
  max-width: 200px;
}

.table.custom-news td {
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  vertical-align: middle;
}

.table.custom-news tr.footer td {
  vertical-align: top;
}

.add-u-btn {
  margin-top: 20px;
}

.alt-thumb {
  margin: auto;
}

.url-error {
  border-color: #ff0000;
}

.url-error:focus {
  border-color: #ff0000;
  box-shadow: 0 0 0 0rem rgba(255, 0, 0, 0.25);
}

.hidden {
  opacity: 0;
}
.w-70px {
  width: 70px;
}
td {
  vertical-align: middle !important;
}
.gallery-img-container {
  max-width: 250px;
}
.orderBox {
  width: 80px;
}

.page-title-bg {
  background: #f1f0f0;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
</style>
