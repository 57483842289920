<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">File Manager Tools</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <FileUploadMultiNew
          :data="fileUpload"
          :onFileUploaded="onFileUploaded"
          :acceptType="acceptList"
        />
        <div class="page-container">
          <file-table
            :data="files"
            :paginate="paginate"
            :onUpdatePaginate="onUpdatePaginate"
            :onUpdateLimit="onUpdateLimit"
            :onDeleteData="onDeleteData"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import FileTable from '@/components/tables/FileTable'
import FileUploadMultiNew from '@/components/FileUploadMultiNew'

export default {
  components: {
    FileUploadMultiNew,
    'file-table': FileTable
  },
  data: () => ({
    enable: true,
    fileUpload: [],
    files: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    },
    acceptList:
      '.doc,.docx,.ppt,.pptx,.xls,.xlsx,.jpg,.jpeg,.gif,.png,.tiff,.zip,.rar,.pdf'
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.FILEMANAGER.LIST,
      createData: ActionFetch.FILEMANAGER.CREATE,
      deleteData: ActionFetch.FILEMANAGER.DELETE
    }),
    fetchList ($page = 1) {
      const payload = {
        sort: 'id',
        order: 'DESC',
        limit: this.paginate.limit,
        page: $page
      }

      this.getList(payload).then(($res) => {
        if ($res.success) {
          this.files = $res.data
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalCount
            : 0
        }
      })
    },
    onDeleteData ($data) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($conRes) => {
          if ($conRes) {
            this.deleteData($data.id).then(($res) => {
              if ($res.success) {
                this.fetchList(this.paginate.page)
              }
            })
          }
        })
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchList($paginate)
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit

      this.fetchList()
    },
    onFileUploaded (data) {
      this.fetchList(this.paginate.page)
    }
  },
  created () {
    this.fetchList()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>
