<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Mobile App</div>
    </div>
    <div class="page-container">
      <form>
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting Page Banner</h5>
            </div>
          </div>
          <hr />

          <div id="thumbnail-section">
            <div id="thumbs-1" class="thumb-item">
              <div class="flex-row">
                <div class="thumbnail-picker-wrapper">
                  <ImageUploadNew
                    :maxFile="1"
                    :dataImage="form.banner"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="(e) => onImageUploaded(e, 'banner')"
                  />
                </div>
                <div class="flex-1 pl-10-px">
                  <b-form-group
                    style="font-weight: bold; color: #666"
                    label="Banner (1920 x 550 pixel)"
                  >
                    <div class="flex-row col-10 no-padding">
                      <p
                        style="
                          margin-right: 5px;
                          flex: 1 15%;
                          line-height: 30px;
                        "
                      >
                        ALT Text
                      </p>
                      <b-form-input
                        class=""
                        type="text"
                        v-model="form.banner.name"
                        placeholder="Typing Something..."
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  v-model="form.title.th"
                  type="text"
                  placeholder="Input Title"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle">
                <b-form-input
                  type="text"
                  v-model="form.subtitle.th"
                  placeholder="Input Subtitle"
                ></b-form-input>
              </b-form-group>
            </b-tab>

            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  v-model="form.title.en"
                  type="text"
                  placeholder="Input Title"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle">
                <b-form-input
                  type="text"
                  v-model="form.subtitle.en"
                  placeholder="Input Subtitle"
                ></b-form-input>
              </b-form-group>
            </b-tab>
          </b-tabs>
          <hr />
          <div>
            <b-btn
              size="sm"
              variant="primary"
              class="mx-1"
              @click="submitPageBanner"
              >Save</b-btn
            >
            <b-btn size="sm" variant="secondary" class="mx-1" type="button"
              >Cancel</b-btn
            >
          </div>

          <div class="flex-row align-center justify-between mt-5">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">
                Edit Content - Download & Install Application
              </h5>
            </div>
          </div>
          <hr />
          <b-tabs content-class="mt-3">
            <b-tab
              title="ภาษาไทย"
              @click="editorTab = 'th'"
              :active="editorTab === 'th'"
            >
              <TextEditor
                :content="form.download_content.th"
                :onUpdateInfo="
                  (e) => onUpdateContent(e, 'download_content', 'th')
                "
              />
              <div id="thumbnail-section" class="mt-4">
                <div id="thumbs-2" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :maxFile="1"
                        :dataImage="form.download_image_desktop.th"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(
                              e,
                              'download_image_desktop',
                              'th'
                            )
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - PC (width 1600px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.download_image_desktop.th.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <div id="thumbnail-section">
                <div id="thumbs-3" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :maxFile="1"
                        :dataImage="form.download_image_mobile.th"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(
                              e,
                              'download_image_mobile',
                              'th'
                            )
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - Mobile (width 800px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.download_image_mobile.th.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab
              title="English"
              @click="editorTab = 'en'"
              :active="editorTab === 'en'"
            >
              <TextEditor
                :content="form.download_content.en"
                :onUpdateInfo="
                  (e) => onUpdateContent(e, 'download_content', 'en')
                "
              />
              <div id="thumbnail-section" class="mt-4">
                <div id="thumbs-2" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :maxFile="1"
                        :dataImage="form.download_image_desktop.en"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(
                              e,
                              'download_image_desktop',
                              'en'
                            )
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - PC (width 1600px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.download_image_desktop.en.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <div id="thumbnail-section">
                <div id="thumbs-3" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :maxFile="1"
                        :dataImage="form.download_image_mobile.en"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(
                              e,
                              'download_image_mobile',
                              'en'
                            )
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - Mobile (width 800px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.download_image_mobile.en.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <hr />

          <div class="flex-row align-center justify-between mt-5">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">
                Edit Content - Repair Procedures via Application
              </h5>
            </div>
          </div>
          <hr />
          <b-tabs content-class="mt-3">
            <b-tab
              title="ภาษาไทย"
              @click="repairTab = 'th'"
              :active="repairTab === 'th'"
            >
              <div id="thumbnail-section" class="mt-4">
                <div id="thumbs-2" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :dataImage="form.repair_image_desktop.th"
                        :maxFile="1"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(
                              e,
                              'repair_image_desktop',
                              'th'
                            )
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - PC (width 1600px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.repair_image_desktop.th.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <div id="thumbnail-section">
                <div id="thumbs-3" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :maxFile="1"
                        :dataImage="form.repair_image_mobile.th"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(e, 'repair_image_mobile', 'th')
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - Mobile (width 800px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.repair_image_mobile.th.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              title="English"
              @click="repairTab = 'en'"
              :active="repairTab === 'en'"
            >
              <div id="thumbnail-section" class="mt-4">
                <div id="thumbs-2" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :dataImage="form.repair_image_desktop.en"
                        :maxFile="1"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(
                              e,
                              'repair_image_desktop',
                              'en'
                            )
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - PC (width 1600px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.repair_image_desktop.en.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <div id="thumbnail-section">
                <div id="thumbs-3" class="thumb-item">
                  <div class="flex-row">
                    <div class="thumbnail-picker-wrapper">
                      <ImageUploadNew
                        :maxFile="1"
                        :dataImage="form.repair_image_mobile.en"
                        :uploadSetting="{ ...uploadSetting }"
                        :onFileUploaded="
                          (e) =>
                            onImageContentUpload(e, 'repair_image_mobile', 'en')
                        "
                      />
                    </div>
                    <div class="flex-1 pl-10-px">
                      <b-form-group
                        style="font-weight: bold; color: #666"
                        label="Upload Graphic - Mobile (width 800px, Height follow width ratio.)"
                      >
                        <div class="flex-row col-10 no-padding">
                          <p
                            style="
                              margin-right: 5px;
                              flex: 1 15%;
                              line-height: 30px;
                            "
                          >
                            ALT Text
                          </p>
                          <b-form-input
                            class=""
                            v-model="form.repair_image_mobile.en.name"
                            type="text"
                            placeholder="Typing Something..."
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <hr />
          <div class="flex-row align-center justify-between mt-5">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Edit Remark</h5>
            </div>
          </div>
          <hr />
          <b-tabs content-class="mt-3">
            <b-tab
              title="ภาษาไทย"
              @click="remarkTab = 'th'"
              :active="remarkTab === 'th'"
            >
              <TextEditor
                :content="form.remark.th"
                :onUpdateInfo="(e) => onUpdateContent(e, 'remark', 'th')"
              />
            </b-tab>

            <b-tab
              title="English"
              @click="remarkTab = 'en'"
              :active="remarkTab === 'en'"
            >
              <TextEditor
                :content="form.remark.en"
                :onUpdateInfo="(e) => onUpdateContent(e, 'remark', 'en')"
              />
            </b-tab>
          </b-tabs>
          <div class="flex-row align-center justify-between mt-3">
            <div class="flex-col" style="flex: 1">
              <span class="text-bold"
                >User Manual Download (PDF file not over 20MB)</span
              >
            </div>
          </div>
          <div class="mt-2">
            <FileUploadNew
              :dataPdf="form.user_manual"
              :onFileUploaded="onFileUploaded"
            />
          </div>
          <hr />
          <div>
            <b-btn
              size="sm"
              variant="primary"
              class="mx-1"
              @click="submitRemark"
              >Save</b-btn
            >
            <b-btn size="sm" variant="secondary" class="mx-1" type="button"
              >Cancel</b-btn
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '@/components/ImageUploadNew'
import TextEditor from '@/components/text-editor/TextEditor'
import FileUploadNew from '@/components/FileUploadNew'

export default {
  data: () => ({
    tab: 'th',
    editorTab: 'th',
    remarkTab: 'th',
    repairTab: 'th',
    uploadSetting: {
      showPreview: true,
      showDelete: true,
      showAlt: false
    },
    form: {}
  }),
  components: {
    ImageUploadNew,
    TextEditor,
    FileUploadNew
  },
  methods: {
    ...mapActions({
      getInfo: ActionFetch.MOBILEAPP.DETAIL,
      updateData: ActionFetch.MOBILEAPP.UPDATE
    }),
    onFileUploaded (data) {
      this.form.user_manual = data && data[0]
    },
    onImageUploaded ($data, $type) {
      this.form[$type].source = $data.source
    },
    onImageContentUpload ($data, $type, $lang) {
      this.form[$type][$lang].source = $data.source
    },
    onUpdateContent ($value, $type, $lang) {
      this.form[$type][$lang] = $value
    },
    submitPageBanner () {
      if (!this.form.banner.source) {
        this.$bvToast.toast(`Please upload banner image`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.title.th) {
        this.$bvToast.toast(`Please input thai title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.title.en) {
        this.$bvToast.toast(`Please input english title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.subtitle.th) {
        this.$bvToast.toast(`Please input thai subtitle`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.subtitle.en) {
        this.$bvToast.toast(`Please input english subtitle`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        const { banner, title, subtitle } = this.form
        const payload = {
          banner,
          title,
          subtitle
        }

        this.updateData(payload).then(($res) => {
          if ($res.success) {
            this.$bvToast.toast(`Update mobile app successful`, {
              title: 'Mobile app updated',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          }
        })
      }
    },
    submitRemark () {
      if (!this.form.download_content.th) {
        this.$bvToast.toast(`Please input thai content`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.download_content.en) {
        this.$bvToast.toast(`Please input english content`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.download_image_desktop.th.source) {
        this.$bvToast.toast(
          `Please upload thai download & install image [PC Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.download_image_desktop.en.source) {
        this.$bvToast.toast(
          `Please upload english download & install image [PC Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.download_image_mobile.th.source) {
        this.$bvToast.toast(
          `Please upload thai download & install image [Mobile Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.download_image_mobile.en.source) {
        this.$bvToast.toast(
          `Please upload english download & install image [Mobile Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.repair_image_desktop.th.source) {
        this.$bvToast.toast(
          `Please upload thai repair procedures via application image [PC Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.repair_image_desktop.en.source) {
        this.$bvToast.toast(
          `Please upload english repair procedures via application image [PC Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.repair_image_mobile.th.source) {
        this.$bvToast.toast(
          `Please upload thai repair procedures via application image [Mobile Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.repair_image_mobile.en.source) {
        this.$bvToast.toast(
          `Please upload english repair procedures via application image [Mobile Version]`,
          {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          }
        )
      } else if (!this.form.remark.th) {
        this.$bvToast.toast(`Please input thai remark`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.remark.en) {
        this.$bvToast.toast(`Please input english remark`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.user_manual.source) {
        this.$bvToast.toast(`Please upload user manual`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        /* eslint-disable camelcase */
        const {
          download_content,
          download_image_desktop,
          download_image_mobile,
          repair_image_desktop,
          repair_image_mobile,
          remark,
          user_manual
        } = this.form
        /* eslint-enable camelcase */

        const payload = {
          download_content,
          download_image_desktop,
          download_image_mobile,
          repair_image_desktop,
          repair_image_mobile,
          remark,
          user_manual
        }

        this.updateData(payload).then(($res) => {
          if ($res.success) {
            this.$bvToast.toast(`Update mobile app successful`, {
              title: 'Mobile app updated',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          }
        })
      }
    },
    onSubmit ($e) {
      $e.preventDefault()
    },
    fetchInfo () {
      this.getInfo().then(($res) => {
        if ($res.success) {
          this.form = $res.data
        }
      })
    }
  },
  created () {
    this.fetchInfo()
  }
}
</script>
<style>
.thumb-item {
  margin-bottom: 10px;
}
.pl-10-px {
  padding-left: 10px;
}
label {
  font-weight: bold;
}
.form-group {
  padding: 0;
}
.no-padding {
  padding: 0;
}
.inline-zone {
  min-width: 25%;
}
.maxWidth {
  max-width: 250px;
  min-width: 120px;
}
.hidden {
  opacity: 0;
}
.text-bold {
  font-weight: bold;
}
</style>
