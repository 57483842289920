<template>
  <div>
    <div class="table-responsive">
      <div class="flex-row align-center justify-between">
        <h5 class="page-title" style="font-weight: 600">Role List</h5>
        <div class="flex-row">
          <b-btn
            size="sm"
            variant="primary"
            class="btn-group-space"
            @click="view(0)"
            >Add New List</b-btn
          >
        </div>
      </div>

      <br />
      <table class="table">
        <thead>
          <tr>
            <th width="80">No</th>
            <th>Role Name</th>
            <th>Role Description</th>
            <th>Active Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in data" :key="list.id">
            <td>
              {{ index + 1 + paginate.page * paginate.limit - paginate.limit }}
            </td>
            <td>{{ list.name }}</td>
            <td>{{ list.description }}</td>
            <td>
              <toggle-button
                :value="list.active === 1"
                :sync="true"
                :labels="true"
                @change="(e) => onSetActive(e, list)"
              />
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  @click="view(list.id)"
                  class="mx-1"
                  >Edit</b-btn
                >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  @click="onDeleteData(list)"
                  >Delete</b-button
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        v-on:change="onUpdatePaginate"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    category: {
      type: Array,
      default: () => []
    },
    onSetActive: Function,
    onUpdatePaginate: Function,
    onUpdateLimit: Function,
    onUpdateSort: Function,
    onDeleteData: Function,
    paginate: {
      type: Object,
      default: () => {
        return {
          limit: 10,
          page: 1,
          totalPage: 1,
          totalRow: 0
        }
      }
    }
  },
  data: () => ({
    perPageOptions: [
      { value: 2, text: '2 / page' },
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    orderList: []
  }),
  methods: {
    view (id) {
      this.$router.push(`/role/role/${id}/edit`)
    },
    renderDate (date) {
      return date ? format(date, 'YYYY-MM-DD') : ''
    },
    renderLang ($data) {
      if (!$data) {
        return ''
      }

      return $data.th ? $data.th : $data.en
    },
    renderImage ($data) {
      if (!$data) {
        return ''
      }

      return `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$data}`
    },
    renderCategory ($data) {
      if (!$data) {
        return ''
      }

      let categoryData = this.category.find(($cat) => {
        return $cat.id === $data.categoryId
      })

      return categoryData ? categoryData.title.th : ''
    },
    updateSorting ($newVal, $data) {
      let dataSort = {
        id: $data.id,
        orderNew: $newVal,
        orderOld: $data.orderNum
      }

      this.onUpdateSort(dataSort)
    }
  },
  watch: {
    'paginate.totalRow': function (newVal, oldVal) {
      this.orderList = []
      for (let ls = 1; ls <= newVal; ls++) {
        this.orderList.push(ls)
      }
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}
.title-table {
  width: 30%;
}
</style>
