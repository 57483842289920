<template>
  <b-modal
    id="add-banner"
    size="xl"
    centered
    no-close-on-backdrop
    title="Add/Edit Banner List"
  >
    <form
      id="add-banner-form"
      name="add-banner-form"
      @submit.prevent="onSubmit"
    >
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            <span class="pr-2">Date Start Slide</span>
            <date-picker
              lang="en"
              placeholder="Select Date"
              :class="{ 'date-required': requiredStartDate }"
              v-model="form.startDate"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            <span class="pr-2">Date Stop Slide</span>
            <date-picker
              lang="en"
              placeholder="Select Date"
              v-model="form.endDate"
              :class="{ 'date-required': requiredEndDate }"
              :not-before="renderDate(form.startDate)"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <ImageUploadNew
            :dataImage="form.pcImages"
            :uploadSetting="{ ...uploadSetting }"
            :isRequired="false"
            :maxFile="1"
            :onFileUploaded="onUploadPcImage"
            :showError="errorImage.pc"
            :subject="'Picture Slide (PC) (1920 x 800 Pixel)'"
          />
        </div>
        <div class="col-md-6">
          <ImageUploadNew
            :dataImage="form.mobileImages"
            :uploadSetting="{ ...uploadSetting }"
            :isRequired="false"
            :maxFile="1"
            :showError="errorImage.mobile"
            :onFileUploaded="onUploadMobileImage"
            :subject="'Picture Slide (Mobile) (800 x 800 Pixel)'"
          />
        </div>
      </div>
      <hr />
      <h6 class="pb-3">Please fill link for click to</h6>
      <div class="flex-row align-center">
        <div class="checkbox-wrapper">
          <p-check
            name="check"
            class="p-icon p-smooth p-bigger"
            color="primary"
            :checked="form.url.status === 'Active'"
            style="margin-right: 20px"
            @change="(e) => swithType(e, 'url')"
          >
            <i slot="extra" class="icon fa fa-check"></i> URL Link
          </p-check>
        </div>
        <input
          type="text"
          :class="{ 'form-control': true }"
          :disabled="form.url.status !== 'Active'"
          v-model="form.url.url"
          @change="(e) => onvalidateUrl(e.target.value)"
          placeholder="Example - https://www.grandunity.co.th/directory"
        />
      </div>
      <br />
      <div class="flex-row align-center">
        <div class="checkbox-wrapper">
          <p-check
            name="check"
            class="p-icon p-smooth p-bigger"
            color="primary"
            :checked="form.urlYoutube.status === 'Active'"
            style="margin-right: 20px"
            @change="(e) => swithType(e, 'urlYoutube')"
          >
            <i slot="extra" class="icon fa fa-check"></i> Youtube Link
          </p-check>
        </div>
        <input
          type="text"
          :class="{ 'form-control': true }"
          :disabled="form.urlYoutube.status != 'Active'"
          placeholder="Example - https://www.youtube.com/path"
          v-model="form.urlYoutube.url"
          @change="(e) => onValidateYoutube(e.target.value)"
        />
      </div>
    </form>
    <template slot="modal-footer">
      <b-button size="sm" variant="primary" type="submit" form="add-banner-form"
        >Save</b-button
      >
      <b-button size="sm" variant="secondary" @click="onCloseCancel"
        >Cancel</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import format from 'date-fns/format'
import ImageUploadNew from '../ImageUploadNew'

export default {
  props: {
    data: Object,
    onSave: Function,
    onRefresh: Function
  },
  components: {
    ImageUploadNew
  },
  data: () => ({
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    },
    errorImage: {
      pc: false,
      mobile: false
    },
    urlValidator: {
      youtube: true,
      url: true
    },
    originValue: {},
    form: {
      module: 'index',
      isOpenNewWindow: 'N',
      url: {
        url: '',
        status: 'Inactive'
      },
      urlYoutube: {
        url: '',
        status: 'Inactive'
      },
      pcImages: {
        source: '',
        name: ''
      },
      mobileImages: {
        source: '',
        name: ''
      },
      startDate: null,
      endDate: null,
      status: 'Inactive',
      visitor: '127.0.0.1',
      createdBy: 1,
      updatedAt: 1
    },
    requiredStartDate: false,
    requiredEndDate: false
  }),
  methods: {
    swithType (value, type) {
      this.form[type].status = value ? 'Active' : 'Inactive'

      if (type === 'url' && value) {
        this.form.urlYoutube.status = 'Inactive'
      } else if (type === 'urlYoutube' && value) {
        this.form.url.status = 'Inactive'
      }
    },
    renderDate ($date) {
      return format($date, 'YYYY-MM-DD')
    },
    onUploadPcImage ($data) {
      this.form.pcImages.source = $data.source
      this.form.pcImages.name = $data.name
    },
    onUploadMobileImage ($data) {
      this.form.mobileImages.source = $data.source
      this.form.mobileImages.name = $data.name
    },
    onvalidateUrl ($value) {
      try {
        if ($value) {
          // eslint-disable-next-line no-new
          new URL($value)
        }

        this.urlValidator.url = true
      } catch ($ex) {
        this.urlValidator.url = false
        this.$bvToast.toast(`URL invalid`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }
    },
    onValidateYoutube ($value) {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
      this.urlValidator.urlYoutube = true

      if ($value) {
        let match = $value.match(regExp)

        if (!match || match[2].length !== 11) {
          this.urlValidator.urlYoutube = false

          this.$bvToast.toast(`youtube invalid`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        }
      }
    },
    onSubmit ($e) {
      $e.preventDefault()

      this.errorImage.pc = !this.form.pcImages.source
      this.errorImage.mobile = !this.form.mobileImages.source
      this.onvalidateUrl(this.form.url.url)
      this.onValidateYoutube(this.form.urlYoutube.url)

      this.requiredStartDate = !this.form.startDate && this.form.endDate
      this.requiredEndDate = !this.form.endDate && this.form.startDate

      if (!this.form.url.url) {
        this.form.url.status = 'Inactive'
      }

      if (!this.form.urlYoutube.url) {
        this.form.urlYoutube.status = 'Inactive'
      }

      if (this.form.url.status !== 'Active') {
        this.form.url.url = ''
      }

      if (this.form.urlYoutube.status !== 'Active') {
        this.form.urlYoutube.url = ''
      }

      if (
        !this.errorImage.mobile &&
        !this.errorImage.pc &&
        !this.requiredStartDate &&
        !this.requiredEndDate
      ) {
        const formSave = this.form

        formSave.startDate = this.form.startDate
          ? format(this.form.startDate, 'YYYY-MM-DD')
          : null
        formSave.endDate = this.form.endDate
          ? format(this.form.endDate, 'YYYY-MM-DD')
          : null

        if (this.urlValidator.url && this.urlValidator.urlYoutube) {
          this.onSave(formSave)
          this.$bvModal.hide('add-banner')
        }
      }
    },
    onCloseCancel () {
      this.onRefresh()
      this.$bvModal.hide('add-banner')
    }
  },
  watch: {
    data: function (newVal, oldVal) {
      this.originValue = Object.assign({}, newVal)
      this.form = Object.assign({}, newVal)
    }
  }
}
</script>

<style scoped>
.file-name {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 10px;
}
.p-icon {
  min-width: 100px;
}
</style>
