<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Setup Privilege Category</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <category-privilege-table
          :data="categories"
          :paginate="paginate"
          :onSetActive="onSetActive"
          :onCreateData="onCreateData"
          :onUpdateData="onUpdateData"
          :onDeleteData="onDeleteData"
          :onUpdatePaginate="onUpdatePaginate"
          :onUpdateLimit="onUpdateLimit"
          :onUpdateSort="onUpdateSort"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import CategoryPrivilegeTable from '@/components/tables/CategoryPrivilegeTable'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  components: {
    'category-privilege-table': CategoryPrivilegeTable
  },
  data: () => ({
    enable: true,
    categories: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.GUFAMILY.CATEGORY.LIST,
      createData: ActionFetch.GUFAMILY.CATEGORY.CREATE,
      updateData: ActionFetch.GUFAMILY.CATEGORY.UPDATE,
      updateSort: ActionFetch.GUFAMILY.CATEGORY.UPDATESORT,
      deleteData: ActionFetch.GUFAMILY.CATEGORY.DELETE
    }),
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchList($paginate)
    },
    onCreateData ($data) {
      let dataSave = Object.assign({}, $data)

      this.createData(dataSave).then(($res) => {
        this.fetchList()
      })
    },
    onUpdateData ($data) {
      let dataSave = Object.assign({}, $data)

      this.updateData(dataSave).then(($res) => {
        if ($res.success) {
          this.fetchList()
        }
      })
    },
    onDeleteData ($data) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($conRes) => {
          if ($conRes) {
            this.deleteData($data.id).then(($res) => {
              if ($res.success) {
                this.fetchList(this.paginate.page)
              }
            })
          }
        })
    },
    onUpdateSort ($data) {
      this.updateSort($data).then(($res) => {
        if ($res.success) {
          this.fetchList(this.paginate.page)
        }
      })
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.paginate.page = 1

      this.fetchList()
    },
    onSetActive ($data, $source) {
      $source.status = $data.value ? 'Active' : 'Inactive'

      let dataSave = {
        id: $source.id,
        status: $source.status
      }

      this.updateData(dataSave)
    },
    fetchList ($page = 1) {
      const payload = {
        orderBy: 'orderNum',
        direction: 'asc',
        limit: this.paginate.limit,
        page: $page
      }

      this.getList(payload).then(($res) => {
        if ($res.success) {
          this.categories = $res.data
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    }
  },
  created () {
    this.fetchList()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>
