<template>
  <div>
    <div class="table-responsive">
      <div class="flex-row align-center justify-between">
        <h5 class="page-title" style="font-weight: 600"></h5>
        <div class="flex-row">
          <b-btn
            size="sm"
            variant="primary"
            class="btn-group-space"
            v-on:click="newData(0)"
            >Add New Item</b-btn
          >
        </div>
      </div>

      <br />
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Images</th>
            <th>Last Update</th>
            <th>Type</th>
            <th>Published</th>
            <th>Start/Stop Auto Published</th>
            <th>Open New Window</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="list in data" :key="list.id">
            <td>
              <b-form-select
                name
                id
                :value="list.orderNum"
                :sync="true"
                v-on:change="updateSorting($event, list)"
              >
                <option
                  v-for="(ls, index) in orderList"
                  :value="ls"
                  :key="index + 1"
                >
                  {{ index + 1 }}
                </option>
              </b-form-select>
            </td>
            <td>
              <img
                :src="renderImage(list.pcImages.source)"
                alt
                class="banner"
              />
            </td>
            <td width="100">{{ renderDate(list.updatedAt) }}</td>
            <td>{{ renderType(list) }}</td>
            <td>
              <toggle-button
                :value="list.status === 'Active'"
                :sync="true"
                :labels="true"
                @change="(e) => onSetActive(e, list)"
                :disabled="validateDisable(list)"
              />
              <!-- <toggle-button
                :value="list.status"
                :sync="true"
                @change="onSetActive(list)"
                :disabled="validateDisable(list)"
              /> -->
            </td>
            <td>
              <p class="start_stop_date">
                Start: {{ renderDate(list.startDate) }}
              </p>
              <p class="start_stop_date">End: {{ renderDate(list.endDate) }}</p>
            </td>
            <td>
              <div v-if="renderType(list) == 'image'">
                <toggle-button
                  :value="list.isOpenNewWindow === 'Y'"
                  :sync="true"
                  :labels="true"
                  @change="(e) => onSetOpenNewWindow(e, list)"
                />
              </div>
              <div v-else>N/A</div>
              <!-- <toggle-button
                :value="list.isOpenNewWindow === 'Y'"
                :sync="true"
                :labels="true"
                @change="(e) => onSetOpenNewWindow(e, list)"
              /> -->
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  v-on:click="edit(list)"
                  class="mx-1"
                  >Edit</b-btn
                >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  @click="onDeleteData(list)"
                  >Delete</b-button
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        v-on:change="onUpdatePaginate"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
    <GuFamAddBannerModal
      :data="editData"
      :onSave="onSave"
      :onRefresh="onRefresh"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'
import GuFamAddBannerModal from '../modals/GuFamAddBannerModal'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    onCreateData: Function,
    onUpdateData: Function,
    onDeleteData: Function,
    onSetActive: Function,
    onUpdatePaginate: Function,
    onUpdateLimit: Function,
    onUpdateSort: Function,
    onSetOpenNewWindow: Function,
    onRefresh: Function,
    paginate: {
      type: Object,
      default: () => {
        return {
          limit: 10,
          page: 1,
          totalPage: 1,
          totalRow: 0
        }
      }
    }
  },
  components: {
    GuFamAddBannerModal
  },
  data: () => ({
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    editData: null,
    orderList: []
  }),
  methods: {
    validateDisable ($data) {
      let result = !!$data.startDate && !!$data.endDate

      return result
    },
    edit ($data) {
      this.editData = Object.assign({}, $data)
      this.$bvModal.show('add-banner')
    },
    renderDate (date) {
      return date ? format(date, 'YYYY-MM-DD') : ''
    },
    renderLang ($data) {
      if (!$data) {
        return ''
      }

      return $data.th ? $data.th : $data.en
    },
    renderImage ($data) {
      if (!$data) {
        return ''
      }

      return `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$data}`
    },
    renderType ($data) {
      let result = 'image'

      if ($data.urlYoutube.status === 'Active') {
        result = 'video'
      }

      return result
    },
    newData () {
      this.edit({
        module: 'index',
        isOpenNewWindow: 'N',
        url: {
          url: '',
          status: 'Inactive'
        },
        urlYoutube: {
          url: '',
          status: 'Inactive'
        },
        pcImages: {
          source: '',
          name: ''
        },
        mobileImages: {
          source: '',
          name: ''
        },
        startDate: null,
        endDate: null,
        status: 'Inactive',
        visitor: '127.0.0.1',
        createdBy: 1,
        updatedAt: 1
      })
    },
    onSave ($data) {
      if (!$data.id) {
        this.onCreateData($data)
      } else {
        this.onUpdateData($data)
      }
    },
    updateSorting ($newVal, $data) {
      let dataSort = {
        id: $data.id,
        orderNew: $newVal,
        orderOld: $data.orderNum
      }

      this.onUpdateSort(dataSort)
    }
  },
  watch: {
    'paginate.totalRow': function (newVal, oldVal) {
      this.orderList = []
      for (let ls = 1; ls <= newVal; ls++) {
        this.orderList.push(ls)
      }
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}
.title-table {
  width: 30%;
}
</style>
