<template>
  <div id="">
    <div class="page-name">
      <div class="page-title">About Us</div>
    </div>

    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Add/Edit Milestone List</h5>
            </div>
          </div>

          <hr />
          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <div class="form-group form-group-row">
                <span>Year</span>
                <b-form-input
                  name="yearTH"
                  v-model="form.year.th"
                  type="text"
                  placeholder="Input year"
                  @keyup="(e) => onKeyUpYear(e, 'th')"
                ></b-form-input>
              </div>
              <br />
              <div class="form-group form-group-row">
                <span>Details</span>
              </div>
              <TextEditor
                :content="form.detail.th"
                :onUpdateInfo="onUpdateContentTH"
              />
            </b-tab>

            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <div class="form-group form-group-row">
                <span>Year</span>
                <b-form-input
                  name="yearEn"
                  v-model="form.year.en"
                  type="text"
                  placeholder="Input year"
                  @keyup="(e) => onKeyUpYear(e, 'en')"
                ></b-form-input>
              </div>
              <br />
              <div class="form-group form-group-row">
                <span>Details</span>
              </div>
              <TextEditor
                :content="form.detail.en"
                :onUpdateInfo="onUpdateContentEN"
              />
            </b-tab>
          </b-tabs>

          <hr />

          <div>
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              size="sm"
              variant="secondary"
              class="mx-1"
              type="button"
              @click="backToList"
              >Cancel</b-btn
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import TextEditor from '@/components/text-editor/TextEditor'

export default {
  data: () => ({
    tab: 'th',
    form: {
      year: {
        th: '',
        en: ''
      },
      detail: {
        th: '',
        en: ''
      }
    },
    yearVerify: {
      th: true,
      en: true
    }
  }),
  components: {
    TextEditor
  },
  methods: {
    ...mapActions({
      getInfo: ActionFetch.ABOUT.MILESTONE.DETAIL,
      createMilestone: ActionFetch.ABOUT.MILESTONE.CREATE,
      updateMilestone: ActionFetch.ABOUT.MILESTONE.UPDATE
    }),
    backToList () {
      this.$router.push(`/about/milestone`)
    },
    onUpdateContentTH ($data) {
      this.form.detail.th = $data
    },
    onUpdateContentEN ($data) {
      this.form.detail.en = $data
    },
    onKeyUpYear ($data, $lang) {
      let regCharactor = /^[0-9]+$/

      if ($data.target.value) {
        this.form.year[$lang] = $data.target.value
          .replace(/ /g, '')
          .toLowerCase()
        this.yearVerify[$lang] = regCharactor.test(this.form.year[$lang])
      }
    },
    fetchMilestone () {
      const payload = {
        id: +this.$route.params.id
      }

      if (+this.$route.params.id) {
        this.getInfo(payload).then(($res) => {
          if ($res.success) {
            this.form = { ...$res.data }
          }
        })
      }
    },
    onSubmit ($e) {
      $e.preventDefault()

      if (!this.form.year.th) {
        this.$bvToast.toast(`Please input year thai`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.year.en) {
        this.$bvToast.toast(`Please input year english`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.detail.th) {
        this.$bvToast.toast(`Please input detail thai`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.detail.en) {
        this.$bvToast.toast(`Please input detail english`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.yearVerify.th) {
        this.$bvToast.toast(`Year thai invalid format`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.yearVerify.en) {
        this.$bvToast.toast(`Year english invalid format`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        if (!this.form.id) {
          this.createMilestone(this.form).then(($res) => {
            if ($res.success) {
              this.$bvToast.toast(`Create milestone successful`, {
                title: 'Milestone created',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })

              setTimeout(() => {
                this.backToList()
              }, 1000)
            }
          })
        } else {
          this.updateMilestone(this.form).then(($res) => {
            if ($res.success) {
              this.$bvToast.toast(`Update milestone successful`, {
                title: 'Milestone updated',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })

              setTimeout(() => {
                this.backToList()
              }, 1000)
            }
          })
        }
      }
    }
  },
  created () {
    this.fetchMilestone()
  }
}
</script>

<style>
.inline-zone {
  margin-right: 10px;
  font-weight: 600;
  min-width: 25%;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
  min-width: 120px;
  max-width: 200px;
}

.table.custom-news td {
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  vertical-align: middle;
}

.table.custom-news tr.footer td {
  vertical-align: top;
}

.add-u-btn {
  margin-top: 20px;
}

.alt-thumb {
  margin: auto;
}

.url-error {
  border-color: #ff0000;
}

.url-error:focus {
  border-color: #ff0000;
  box-shadow: 0 0 0 0rem rgba(255, 0, 0, 0.25);
}

.hidden {
  opacity: 0;
}
.w-70px {
  width: 70px;
}
td {
  vertical-align: middle !important;
}
.gallery-img-container {
  max-width: 250px;
}
.orderBox {
  width: 80px;
}
</style>
