<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">FOREIGN BUYER GUILD</div>
    </div>
    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting Page Banner</h5>
            </div>
          </div>
          <hr />

          <div id="thumbnail-section">
            <div id="thumbs-1" class="thumb-item">
              <div class="flex-row">
                <div class="thumbnail-picker-wrapper">
                  <ImageUploadNew
                    :dataImage="form.banner_image"
                    :maxFile="1"
                    :showError="uploadError"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="onUploaded"
                  />
                </div>
                <div class="flex-1 pl-10-px">
                  <b-form-group
                    style="font-weight: bold; color: #666"
                    label="Banner (1920 x 550 pixel)"
                  >
                    <div class="flex-row col-10 no-padding">
                      <p
                        style="
                          margin-right: 5px;
                          flex: 1 15%;
                          line-height: 30px;
                        "
                      >
                        ALT Text
                      </p>
                      <b-form-input
                        class=""
                        type="text"
                        v-model="form.banner_image.name"
                        placeholder="Typing Something..."
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  type="text"
                  placeholder="Input Title"
                  v-model="form.title.th"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle">
                <b-form-input
                  type="text"
                  placeholder="Input Subtitle"
                  v-model="form.subtitle.th"
                ></b-form-input>
              </b-form-group>
            </b-tab>

            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <b-form-group style="font-weight: 600" label="Title">
                <b-form-input
                  type="text"
                  placeholder="Input Title"
                  v-model="form.title.en"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle">
                <b-form-input
                  type="text"
                  placeholder="Input Subtitle"
                  v-model="form.subtitle.en"
                ></b-form-input>
              </b-form-group>
            </b-tab>
          </b-tabs>
          <hr />
          <div>
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              size="sm"
              variant="secondary"
              class="mx-1"
              type="button"
              @click="fetchInfo"
              >Cancel</b-btn
            >
          </div>
          <hr />
          <div class="flex-row align-center justify-between mt-5">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Edit Foreign Buyer Guide Content</h5>
            </div>
          </div>
          <hr />
          <b-tabs content-class="mt-3">
            <b-tab
              title="ภาษาไทย"
              @click="editorTab = 'th'"
              :active="editorTab === 'th'"
            >
              <div class="form-group form-group-row">
                <span>Details</span>
              </div>
              <TextEditor
                :content="form.content.th"
                :onUpdateInfo="(e) => onUpdateContent(e, 'th')"
              />
            </b-tab>

            <b-tab
              title="English"
              @click="editorTab = 'en'"
              :active="editorTab === 'en'"
            >
              <div class="form-group form-group-row">
                <span>Details</span>
              </div>
              <TextEditor
                :content="form.content.en"
                :onUpdateInfo="(e) => onUpdateContent(e, 'en')"
              />
            </b-tab>
          </b-tabs>

          <div class="flex-row align-center justify-between mt-3">
            <div class="flex-col" style="flex: 1">
              <span class="text-bold"
                >User Manual Download (PDF file not over 20MB)</span
              >
            </div>
          </div>
          <div class="mt-2">
            <FileUploadNew
              :dataPdf="form.user_manual"
              :onFileUploaded="onFileUploaded"
            />
          </div>

          <div class="mt-4">
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              size="sm"
              variant="secondary"
              class="mx-1"
              type="button"
              @click="fetchInfo"
            >
              Cancel
            </b-btn>
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '@/components/ImageUploadNew'
import TextEditor from '@/components/text-editor/TextEditor'
import FileUploadNew from '@/components/FileUploadNew'

export default {
  data: () => ({
    tab: 'th',
    editorTab: 'th',
    uploadSetting: {
      showPreview: true,
      showDelete: true,
      showAlt: false
    },
    uploadError: false,
    form: {
      banner_image: {
        source: '',
        name: ''
      },
      title: {
        th: '',
        en: ''
      },
      subtitle: {
        th: '',
        en: ''
      },
      content: {
        th: '',
        en: ''
      },
      user_manual: {
        source: ''
      }
    }
  }),
  components: {
    ImageUploadNew,
    TextEditor,
    FileUploadNew
  },
  methods: {
    ...mapActions({
      getInfo: ActionFetch.FOREIGNBUYER.DETAIL,
      updateData: ActionFetch.FOREIGNBUYER.UPDATE
    }),
    onSubmit ($e) {
      $e.preventDefault()

      this.uploadError = !this.form.banner_image.source

      if (this.uploadError) {
        this.$bvToast.toast(`Please upload banner image`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.title.th) {
        this.$bvToast.toast(`Please input thai title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.title.en) {
        this.$bvToast.toast(`Please input english title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.content.th) {
        this.$bvToast.toast(`Please input thai content`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.content.en) {
        this.$bvToast.toast(`Please input english content`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        this.updateData(this.form).then(($res) => {
          if ($res.success) {
            this.$bvToast.toast(`Update foreign buyer guide successful`, {
              title: 'Foreign Buyer Guide updated',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
            this.fetchInfo()
          }
        })
      }
    },
    onFileUploaded (data) {
      if (data && data[0]) {
        this.form.user_manual = data[0]
      } else {
        this.form.user_manual = null
      }
    },
    onUploaded ($data) {
      this.form.banner_image.source = $data.source
    },
    onUpdateContent ($value, $lang) {
      this.form.content[$lang] = $value
    },
    fetchInfo () {
      this.getInfo().then(($res) => {
        if ($res.success) {
          this.form = $res.data
        }
      })
    }
  },
  created () {
    this.fetchInfo()
  }
}
</script>
<style>
.thumb-item {
  margin-bottom: 10px;
}
.pl-10-px {
  padding-left: 10px;
}
label {
  font-weight: bold;
}
.form-group {
  padding: 0;
}
.no-padding {
  padding: 0;
}
.inline-zone {
  min-width: 25%;
}
.maxWidth {
  max-width: 250px;
  min-width: 120px;
}
.hidden {
  opacity: 0;
}
.text-bold {
  font-weight: bold;
}
</style>
