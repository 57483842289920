<template>
  <div>
    <a-upload-dragger
      name="file"
      :multiple="true"
      :accept="acceptType"
      :headers="headerUpload"
      :action="urlUpload"
      :fileList="fileList"
      :remove="handleRemove"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading
        company data or other band files
      </p>
    </a-upload-dragger>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    test: String,
    onFileUploaded: Function,
    acceptType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      urlUpload: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/file-manager`,
      headerUpload: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('cmsUser')).token
        }`
      },
      fileList: []
    }
  },
  methods: {
    handleChange (info) {
      this.fileList = info.fileList.map(($objFileList) => {
        return Object.assign({}, $objFileList)
      })
      const status = info.file.status
      if (status === 'done') {
        this.onFileUploaded(
          info.fileList.map(($dataFile) => {
            return {
              source: `${info.file.response.data.url}`,
              status: 'Active'
            }
          })
        )
      }
    },
    handleRemove () {
      this.fileList = []
      this.onFileUploaded([])
    },
    initialSystem () {
    }
  },
  created () {
    this.initialSystem()
  },
  watch: {
    data: function ($value, $oldValue) {
      if ($value) {
        this.fileList = $value.map(($data, $index) => {
          return {
            uid: $index,
            name: $data.source,
            status: 'done',
            url: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$data.source}`
          }
        })
      }
    }
  }
}
</script>
