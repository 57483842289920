<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">About Us</div>
    </div>
    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Edit Branding Page</h5>
            </div>
          </div>

          <hr />

          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <TextEditor
                :content="form.content.th"
                :onUpdateInfo="onUpdateContentTH"
              />
            </b-tab>

            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <TextEditor
                :content="form.content.en"
                :onUpdateInfo="onUpdateContentEN"
              />
            </b-tab>
          </b-tabs>

          <hr />

          <div>
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              size="sm"
              variant="secondary"
              class="mx-1"
              type="button"
              @click="fetchBranding"
              >Cancel</b-btn
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import TextEditor from '@/components/text-editor/TextEditor'

export default {
  data: () => ({
    tab: 'th',
    form: {
      content: {
        th: '',
        en: ''
      }
    }
  }),
  components: {
    TextEditor
  },
  methods: {
    ...mapActions({
      getInfo: ActionFetch.ABOUT.BRANDING.DETAIL,
      updateBranding: ActionFetch.ABOUT.BRANDING.UPDATE
    }),
    fetchBranding () {
      this.getInfo().then(($res) => {
        if ($res.success) {
          this.form = $res.data
        }
      })
    },
    onUpdateContentTH ($data) {
      this.form.content.th = $data
    },
    onUpdateContentEN ($data) {
      this.form.content.en = $data
    },
    onSubmit ($e) {
      $e.preventDefault()

      if (!this.form.content.th) {
        this.$bvToast.toast(`Please input content thai`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.content.en) {
        this.$bvToast.toast(`Please input content english`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        this.updateBranding(this.form).then(($res) => {
          if ($res.success) {
            this.form = $res.data

            this.$bvToast.toast(`Update branding page successful`, {
              title: 'Setting branding page updated',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          }
        })
      }
    }
  },
  created () {
    this.fetchBranding()
  }
}
</script>
<style>
</style>
