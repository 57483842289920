<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">About Us</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <milestone-table
          :data="milestone"
          :paginate="paginate"
          :onSetActive="onSetActive"
          :onUpdatePaginate="onUpdatePaginate"
          :onUpdateLimit="onUpdateLimit"
          :onUpdateSort="onUpdateSort"
          :onDeleteMilestone="onDeleteMilestone"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import MilestoneTable from '@/components/tables/MilestoneTable'

export default {
  components: {
    'milestone-table': MilestoneTable
  },
  data: () => ({
    enable: true,
    milestone: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: 0
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.ABOUT.MILESTONE.LIST,
      updateSort: ActionFetch.ABOUT.MILESTONE.UPDATESORT,
      updateMilestone: ActionFetch.ABOUT.MILESTONE.UPDATE,
      deleteMilestone: ActionFetch.ABOUT.MILESTONE.DELETE
    }),
    fetchMileStone ($page = 1) {
      const payload = {
        orderBy: 'orderNum',
        direction: 'asc',
        limit: this.paginate.limit,
        page: $page
      }

      this.getList(payload).then(($res) => {
        if ($res.success) {
          this.milestone = $res.data
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    },
    onDeleteMilestone ($id) {
      this.$bvModal
        .msgBoxConfirm(`do you want to delete?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true,
          okTitle: 'Delete'
        })
        .then(($conDelete) => {
          if ($conDelete) {
            this.deleteMilestone($id).then(($res) => {
              if ($res.success) {
                this.fetchMileStone(this.paginate.page)
              } else {
                this.$bvToast.toast($res.message, {
                  title: 'Error',
                  autoHideDelay: 2000,
                  appendToast: true,
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right',
                  solid: true
                })
              }
            })
          }
        })
    },
    onSetActive ($toggle, $data) {
      $data.status = $toggle.value ? 'Active' : 'Inactive'

      let dataSave = {
        id: $data.id,
        status: $data.status
      }

      this.updateMilestone(dataSave)
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchMileStone($paginate)
    },
    onUpdateSort ($data) {
      this.updateSort($data).then(($res) => {
        if ($res.success) {
          this.fetchMileStone(this.paginate.page)
        }
      })
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.paginate.page = 1

      this.fetchMileStone()
    }
  },
  created () {
    this.fetchMileStone()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>
