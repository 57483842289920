<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">User Manager</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <user-table
          :data="users"
          :paginate="paginate"
          :onSetActive="onSetActive"
          :onUpdatePaginate="onUpdatePaginate"
          :onUpdateLimit="onUpdateLimit"
          :onDeleteData="onDeleteData"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import UserTable from '@/components/tables/UserTable'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  components: {
    'user-table': UserTable
  },
  data: () => ({
    enable: true,
    users: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: 0
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.USER.LIST,
      createData: ActionFetch.USER.CREATE,
      updateData: ActionFetch.USER.UPDATE,
      deleteData: ActionFetch.USER.DELETE
    }),
    onDeleteData ($data) {
      const h = this.$createElement

      const messageVNode = h('div', { class: 'text-center' }, [
        h('div', { class: 'text-center' }, [
          `Would you like to delete this user account?`
        ])
      ])

      this.$bvModal
        .msgBoxConfirm(messageVNode, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'text-center',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($conRes) => {
          if ($conRes) {
            this.deleteData($data.id).then(($res) => {
              if ($res.success) {
                this.paginate.page = 1
                this.fetchList(this.paginate.page)
              }
            })
          }
        })
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchList($paginate)
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.paginate.page = 1
      this.fetchList()
    },
    onSetActive ($data, $source) {
      $source.status = $data.value

      let dataSave = {
        id: $source.id,
        status: $source.status
      }

      this.updateData(dataSave)
    },
    fetchList ($page = 1) {
      const payload = {
        orderBy: 'id',
        direction: 'asc',
        limit: this.paginate.limit,
        page: $page
      }

      this.getList(payload).then(($res) => {
        if ($res.success) {
          this.users = $res.data
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalCount
            : 0
        }
      })
    }
  },
  created () {
    this.fetchList()
  }
}
</script>
