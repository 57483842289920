<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="20"></th>
            <th width="250">File Name</th>
            <th>Size</th>
            <th>Uploaded Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="list of data" :key="list.id">
            <td class="file-icon">
              <i class="fal fa-file-alt"></i>
            </td>
            <td>{{ list.name }}</td>
            <td>{{ (list.size / 1000000).toFixed(2) }} MB</td>
            <td>{{ renderDate(list.uploadAt) }}</td>
            <td>
              <b-btn
                size="sm"
                variant="outline-primary"
                class="mx-1"
                @click="downloadFile(list)"
                >Download</b-btn
              >
              <b-btn
                size="sm"
                variant="outline-primary"
                class="mx-1"
                @click="copyUrl(list)"
                >Copy URL</b-btn
              >
              <b-button
                size="sm"
                variant="outline-danger"
                class="btn-group-space"
                @click="onDeleteData(list)"
                >Delete</b-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        v-on:change="onUpdatePaginate"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
    <div id="urlCpyZone" class="hidden"></div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import FileSaver from 'file-saver'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    paginate: {
      type: Object,
      default: () => {
        return {
          limit: 10,
          page: 1,
          totalPage: 1,
          totalRow: 0
        }
      }
    },
    onUpdatePaginate: Function,
    onUpdateLimit: Function,
    onDeleteData: Function
  },
  components: {},
  data: () => ({
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ]
  }),
  methods: {
    getSize ($data) {
      let result = $data.size / 1024

      if (result < 1) {
        result = `${$data.size} KB`
      } else {
        result = `${result.toFixed(2)} MB`
      }

      return result
    },
    async downloadFile ($data) {
      try {
        const res = await fetch(
          `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$data.url}`
        )
        const blob = await res.blob()

        await FileSaver.saveAs(blob, $data.name)
      } catch ($ex) {
        this.$bvToast.toast(`File not found`, {
          title: 'Cannot download file',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }
    },
    renderDate (date) {
      return date ? format(date, 'YYYY-MM-DD') : ''
    },
    copyUrl ($data) {
      const inputDestination = document.createElement('input')

      inputDestination.value = `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$data.url}`

      document.getElementById('urlCpyZone').appendChild(inputDestination)
      inputDestination.select()
      document.execCommand('copy')
      this.$bvToast.toast(`Copy url successful`, {
        title: 'Copy',
        autoHideDelay: 2000,
        appendToast: true,
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        solid: true
      })
      setTimeout(() => {
        document.getElementById('urlCpyZone').innerHTML = ''
      }, 1000)
    }
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.file-icon i {
  font-size: 35px;
  color: #1890ff;
}
</style>
